@import "../../../shared/colors/colors.module.css";

.formField {
  display: flex;
  flex-direction: column;
  gap: var(--inner-spacing-3);
  width: 100%;
}

.textField {
  display: flex;
  position: relative;
  padding: 0;
  border: none;
  align-items: center;
  border-radius: var(--border-radius-elevation-3);
  background-color: var(--color-bg-neutral-subtle);
  max-inline-size: 100%;
  padding-inline-start: var(--inner-spacing-2);
  padding-inline-end: calc(var(--inner-spacing-3) + var(--icon-size-2));
  padding-block: var(--inner-spacing-3);
  box-shadow: inset 0 0 0 var(--border-width-1)
    var(--color-bd-on-neutral-subtle);
  cursor: pointer;
}

.formField[data-invalid] .textField {
  box-shadow: 0 0 0 var(--border-width-1) var(--color-bd-negative);
}

.formField[data-size="small"] .textField {
  padding-block: var(--inner-spacing-2);
}

.textField[data-focus-visible] {
  box-shadow:
    0 0 0 2px var(--color-bg),
    0 0 0 4px var(--color-bd-focus);
}

.textField[data-hovered] {
  background-color: var(--color-bg-neutral-subtle-hover);
  box-shadow: inset 0 0 0 var(--border-width-1)
    var(--color-bd-on-neutral-subtle-hover);
}

.textField [data-icon] {
  position: absolute;
  right: var(--inner-spacing-2);
}

.necessityIndicator {
  color: var(--color-fg-negative);
  margin-inline-start: var(--inner-spacing-1);
}

.errorText {
  color: var(--color-fg-negative);
}

.description {
  color: var(--color-fg-neutral);
}

.fieldValue {
  text-align: left;
  flex: 1;
}

.fieldValue [data-icon] {
  display: none;
}

.popover {
  background-color: var(--color-bg-elevation-3);
  border-radius: var(--border-radius-elevation-3);
  z-index: var(--z-index-99);
  box-shadow: var(--box-shadow-1);
  min-inline-size: var(--trigger-width);
  max-height: inherit;
  min-height: unset;
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  padding-inline: var(--inner-spacing-4);
  padding-block: var(--inner-spacing-4);
}

.item [data-icon] {
  margin-inline-end: var(--inner-spacing-1);
}

.item:first-of-type {
  border-top-left-radius: var(--border-radius-elevation-3);
  border-top-right-radius: var(--border-radius-elevation-3);
}

.item:last-of-type {
  border-bottom-left-radius: var(--border-radius-elevation-3);
  border-bottom-right-radius: var(--border-radius-elevation-3);
}

.item:not([data-disabled]) {
  cursor: pointer;
}

.item[data-hovered] {
  background-color: var(--color-bg-accent-subtle-hover);
}

.item[data-selected] {
  background-color: var(--color-bg-accent-subtle-active);
}

.item:not([data-disabled]) {
  @each $color in colors {
    &[data-color="$(color)"] {
      color: var(--color-fg-$(color));
    }
  }
}

.item[data-disabled] {
  opacity: var(--opacity-disabled);
  cursor: not-allowed;
}

.item[data-focus-visible] {
  box-shadow:
    inset 0 0 0 2px var(--color-bg),
    inset 0 0 0 4px var(--color-bd-focus);
}

.item [data-separator] {
  border-top: var(--border-width-1) solid var(--color-bd);
  padding: 0;
}

/* this is required so that separator ( <Item isSeprator /> ) if passed with a text as children, the text is hidden */
.item [data-separator] > * {
  display: none;
}

/* making sure the first and last child are not displayed when they have the data-separator attribute */
.item:is(:first-child, :last-child):is([data-separator]) {
  display: none;
}
